<template>
  <div class="row no-gutters">
    <div class="col-lg-6 rate-plan-options">
      <h4>{{ ratePlanSummary }}</h4>
      <span class="urgency" v-show="availability.allotment < 3">{{ $i18n('houses.show.hurry_up_message', {number: availability.allotment || 0}) }} </span>
      <read-more :trunc-limit="100"><span class="wrap" v-html="ratePlanDescription"></span></read-more>
    </div>

    <div class="col-lg-6 room-config" v-if="ratePlanAvailable">
      <h3 class="price">{{ markUpPrice(availability.price_per_night, availability.percentage_per_night) | currency({ symbol: this.currencySymbol }) }} <span>{{ $i18n('houses.show.per_night') }}</span></h3>
      <div class="styled-button" v-if="roomLeftCountForRatePlan() <= 1 || this.onlyBookOneAtATime">
        <button
          key="roomSelected"
          class="full-width add-room-button"
          :disabled="isRoomDisabled()"
          @click="setRoomSize(1), changeRoomSize(), setRoomBtnDisabled()"
          title="Select Room">
          {{ $i18n('houses.show.add_to_cart') }}
        </button>
      </div>
      <div class="styled-select" v-else>
        <select
          class="select-rooms"
          v-on:change="changeRoomSize(), setRoomBtnDisabled()"
          v-model="roomSize"
          :disabled="isRestrictedByDates()">
          <option selected disabled value="0">{{ displayFormattedRoomSelect() }}</option>
          <option v-for="number in roomCountForRatePlan({ratePlanId: ratePlan.id, masterRatePlanId: ratePlan.master_id, masterRoomId: masterRoomId})" :value="number" :key="number">
          {{ displayFormattedRoom(number) }}
          </option>
        </select>
      </div>
      <div class="room-container-wrapper">
        <guest-info
          v-for="(item, index) in this.lineItemsForRatePlan"
          :key="item.index"
          v-bind:lineItem="item"
          :accommodates="roomAccommodates"
          :infantCountsAvailable="infantCountsAvailable"
          v-bind:id="index">
        </guest-info>
      </div>

      <div class="room-container-wrapper date-restriction-warning" v-if="isRestrictedByDates()">
        <p class="text-danger" v-if="ratePlanRestrictions && ratePlanRestrictions.disable_checkin">
          {{ $i18n('houses.show.checkin_date_restriction', {date: $store.state.order.starts_at}) }}
        </p>
        <p class="text-danger" v-if="ratePlanRestrictions && ratePlanRestrictions.disable_checkout">
          {{ $i18n('houses.show.checkout_date_restriction', {date: $store.state.order.ends_at}) }}
        </p>
    </div>
    </div>
    <div class="col-lg-6 room-config" v-else>
      <div class="row no-gutters sold-out">
        <div class="col-9 col-md-10">
          <h3>{{$i18n('houses.show.unavailable')}}</h3>
          <p v-if="minDayRequired">{{$i18n('houses.show.rate_plan_unavailable_min', {days: this.ratePlanUnavailableDays})}}</p>
          <p v-else>{{$i18n('houses.show.rate_plan_unavailable_max', {days: this.ratePlanUnavailableDays})}}</p>
        </div>
      </div>
    </div>

    <div class="mt-2 text-secondary">
      <b>{{ $i18n('airhost.checkout.address.cancellation_policy') }}</b>:<br>
      <span v-html="getPolicyText()"></span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import guestInfo from 'components/house_show/guest_info'
export default {
  components: { guestInfo },
  props: {
    masterRoomId: Number,
    ratePlan: Object,
    ratePlanAvailability: Array,
    ratePlanRestrictions: Object,
  },
  data: function() {
    return {
      availability: {
        max_allotment: 0,
        min_stay: null,
        max_stay: null,
        price_per_night: 0,
        percentage_per_night: 0,
      },
      roomSize: 0,
      lineItems: [],
      currencySymbol: $airhost_settings.currencies[$airhost_settings.currency].symbol,
      ratePlanUnavailableDays: 0,
      ratePlanUnavailableReason: '',
      onlyBookOneAtATime: this.$store.state.onlyBookOneAtATime,
    }
  },

  computed: {
    ...mapGetters([
      'roomCountForRatePlan',
      'numberOfDays'
    ]),
    ratePlanAvailable: function() {
      if (this.availability.min_stay && this.availability.min_stay > this.numberOfDays) {
        this.ratePlanUnavailableDays = this.availability.min_stay;
        this.ratePlanUnavailableReason = 'min';
        return false;
      } else if (this.availability.max_stay && this.availability.max_stay < this.numberOfDays) {
        this.ratePlanUnavailableDays = this.availability.max_stay;
        this.ratePlanUnavailableReason = 'max';
        return false;
      } else {
        return true;
      }
    },
    minDayRequired: function() {
      return this.ratePlanUnavailableReason == 'min'
    },
    ratePlanSummary: function(){
      return this.ratePlan.summary[I18n.locale]
    },
    ratePlanDescription: function() {
      return this.ratePlan.description2[I18n.locale]
    },
    lineItemsForRatePlan: function(){
      this.lineItems = this.$store.state.order.line_items.filter(
        item => item.variant_id == this.ratePlan.id
      )
      this.roomSize = this.lineItems.length
      return this.lineItems
    },
    roomSettings: function(){
      let room = this.$store.state.rooms.find(
        (r) => r.id == this.ratePlan.room_id
      )
      return room.settings
    },
    roomAccommodates: function(){
      let room = this.$store.state.rooms.find(
        (r) => r.id == this.ratePlan.room_id
      )
      return room.accommodates
    },
    infantCountsAvailable: function(){
      let room = this.$store.state.rooms.find(
          (r) => r.id == this.ratePlan.room_id
      )
      return room.infant_counts_available
    },
    roomType: function(){
      let room = this.$store.state.rooms.find(
        (r) => r.id == this.ratePlan.room_id
      )
      return room.room_style
    }
  },

  methods:{
    displayFormattedRoomSelect: function() {
      if (this.$store.state.isApartmentHouseStyle) {
        return I18n.t('houses.show.select_building')
      } else if (this.roomType == 'dormitory') {
        return I18n.t('houses.show.select_bed')
      } else {
        return I18n.t('houses.show.select_room')
      }
    },
    displayFormattedRoom: function(count) {
      if (this.$store.state.isApartmentHouseStyle) {
        return I18n.t('houses.show.buildings', {count})
      } else if (this.roomType == 'dormitory') {
        return I18n.t('houses.show.beds', {count})
      } else {
        return I18n.t('houses.show.rooms', {count})
      }
    },
    markUpPrice: function(price, percentage){
      // skip markup for now, as the price is precalculated in airhost core with percentage already
      // let percent = 1.0
      // if (percentage){
      //   percent = parseFloat(percentage) / 100.0
      // }
      // return (price * percent / $airhost_settings.rate).toFixed(2)
      return (price / $airhost_settings.rate).toFixed(2)
    },
    getPolicyText: function() {
      return this.ratePlan.policy.replace(/\n/g, '<br>')
    },
    changeRoomSize: function(event) {
      let item_count = this.lineItems.length
      let item_diff = this.roomSize - item_count

      if (item_diff > 0) {
        this.$store.dispatch("addItemsByRatePlan", {ratePlan: this.ratePlan, count: item_diff})
      } else if (item_diff < 0) {
        let lineItemsToDelete = this.lineItems.splice(this.roomSize, item_count - this.roomSize)
        this.$store.dispatch("removeItems", lineItemsToDelete)
      }
    },
    setAvailability: function() {
      this.availability.max_allotment = Math.min(...this.ratePlanAvailability.map((data) => data.allotment).filter(Number))

      let startDateAvailability = this.ratePlanAvailability[0]
      this.availability.min_stay = startDateAvailability.min_stay // nullable
      this.availability.max_stay = startDateAvailability.max_stay // nullable

      let total_price = 0
      if (this.ratePlan.sync_discount === true) {
        total_price = this.ratePlanAvailability.map((data) => data.price).reduce((a,b) => a + b, 0)
      } else {
        total_price = this.ratePlanAvailability.map((data) => data.base_price).reduce((a,b) => a + b, 0)
      }
      let total_percentage = this.ratePlanAvailability.map((data) => data.percentage).reduce((a,b) => a + b, 0)
      let nights = this.ratePlanAvailability.length
      this.availability.price_per_night = total_price / nights
      this.availability.percentage_per_night = total_percentage / nights
    },
    setRoomBtnDisabled: function() {
      let roomLeftCount = this.roomLeftCountForRatePlan()
      // If the size of left rooms is equal to the size of rooms added, set the button to disabled.
      // Otherwise, keep the button enabled.
      let value = roomLeftCount == this.roomSize
      this.$store.commit("updateRoomBtnDisabled", { roomId: this.masterRoomId, value: value })
    },
    isRestrictedByDates: function() {
      if (this.ratePlanRestrictions && (this.ratePlanRestrictions.disable_checkin || this.ratePlanRestrictions.disable_checkout)) {
        return true
      }
      return false
    },
    isRoomDisabled: function() {
      if (this.isRestrictedByDates()) {
        return true
      }

      if (this.onlyBookOneAtATime) {
        return this.$store.state.order.line_items.length > 0
      } else {
        return this.$store.state.roomBtnDisabled[this.masterRoomId] || false
      }
    },
    setRoomSize: function(size) {
      this.$set(this, 'roomSize', size)
    },
    roomLeftCountForRatePlan: function() {
      return this.roomCountForRatePlan({ ratePlanId: this.ratePlan.id, masterRatePlanId: this.ratePlan.master_id, masterRoomId: this.masterRoomId })
    },
    updateButtonStateBasedOnRestrictions: function() {
      if (this.ratePlanRestrictions && (this.ratePlanRestrictions.disable_checkin || this.ratePlanRestrictions.disable_checkout)) {
        this.$store.commit("updateRoomBtnDisabled", { roomId: this.masterRoomId, value: true })
      }
    },
  },

  watch: {
    ratePlanAvailability: function() {
      this.setAvailability()
    },
    ratePlanRestrictions: {
      handler: function() {
        this.updateButtonStateBasedOnRestrictions()
      },
      deep: true
    }
  },
  mounted() {
    this.setAvailability()
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  overflow-wrap: break-word;
}
.styled-button {
  width: 100%;
  height: 50px;
  background-color: var(--white);
  border-radius: 3px;
  border: 1px solid #d2d8dd;
}
.add-room-button {
  width: 100%;
  height: 30px;
  margin-top: 10px;
  background-color: var(--white);
  border: 0px;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--gray);
  &:hover:not([disabled]) {
    color: var(--primary);
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    opacity: .3;
  }
}
</style>
