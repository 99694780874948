import ReadMoreComponent from 'components/utils/read_more.vue'
import { orderModel }  from 'store/house_show/model';
let truncate = require('html-truncate');
let AirhostPlugin = {}
AirhostPlugin.install = function (Vue, options) {

  // translation function
  Vue.prototype.$i18n = function (text, args) {
    return window.I18n.t(text, args)
  };

  // function to convert json into querystring
  Vue.prototype.$json_to_params = function(data) {
    return Object.keys(data).map(function(k) {
      return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
    }).join('&')
  };

  // function to get param values out of querystring
  Vue.prototype.$params = function(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };

  // function to calculate price
  Vue.prototype.$calculatePrice = function (amount) {
    return (amount / $airhost_settings.rate).toFixed(2)
  };
  Vue.prototype.$currencySymbol = function (currency = undefined) {
    if (currency !== undefined) {
      return $airhost_settings.currencies[currency].symbol
    } else {
      return $airhost_settings.currencies[$airhost_settings.currency].symbol
    }
  };

  // function to truncate html string
  Vue.prototype.$truncate_html_str = function (str, length) {
    return truncate(str, length);
  };

  // function to sort string alphabetically
  Vue.prototype.$alphaCompareFunc = function (a, b) {
    if(!a){return 1}
    if(!b){return -1}
    return a === b ? 0 : a > b ? 1 : -1;
  };
  // Update variables after fetching new avaibility
  Vue.prototype.$updatePageData = function (that, data, numberOfDays) {
    that.$store.commit("updateOrder", new orderModel())
    that.$store.commit("updateAvailability", data.availability)
    that.$store.commit("updatePloicy", data.policies)
    // Store restrictions data if available
    if (data.restrictions) {
      that.$store.commit("updateRestrictions", data.restrictions)
    }
    // set total number of days
    that.$store.commit("updateNumberOfDays", numberOfDays)
    // set currency of order
    that.$store.dispatch("setOrderCurrency", $airhost_data['houseCurrency'])
    // room sort
    that.$store.dispatch("initializeRoomSort")
  };

  Vue.component('readMore', ReadMoreComponent);
};
export default AirhostPlugin
